import React from "react"
import Img from "gatsby-image"
import styles from "./gallery.module.scss"

export default function Gallery({ images }) {
  return (
    <div className={styles.gallery}>
      <div>
        {images.map(image => (
          <a
            key={image.id}
            href={image.publicURL}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.image}
          >
            <Img fluid={image.sharp.fluid} />
          </a>
        ))}
      </div>
    </div>
  )
}
