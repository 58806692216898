import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Title from "../title"
import cn from "classnames"
import styles from "./products.sidebar.module.scss"

export default function ProductsSidebar({ className }) {
  return (
    <StaticQuery
      query={query}
      render={data => (
        <aside className={cn(styles.sidebar, className)}>
          <Title className={styles.title} headingLevel="h4">
            ÜRÜNLER
          </Title>
          <ul className={styles.menu}>
            {data.allMarkdownRemark.edges.map(({ node }) => (
              <li>
                <Link to={`/urunler/${node.frontmatter.slug}`}>
                  {node.frontmatter.title}
                </Link>
              </li>
            ))}
          </ul>
        </aside>
      )}
    />
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/content/products/**/*.md" } }
      sort: { order: ASC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          frontmatter {
            slug
            title
          }
        }
      }
    }
  }
`
