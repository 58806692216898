import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Container from "../components/container"
import PageHeader from "../components/page-header"
import SEO from "../components/seo"
import Gallery from "../components/gallery"
import styles from "./product.module.scss"
import ProductsSidebar from "../components/products-sidebar"

export default function ProductPageTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <PageHeader
        title={frontmatter.title}
        image={frontmatter.image.sharp.fluid}
      />
      <Container className={styles.container}>
        <ProductsSidebar className={styles.sidebar} />
        <div className={styles.content}>
          {frontmatter.galleryImages && (
            <Gallery images={frontmatter.galleryImages} />
          )}
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        image {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        galleryImages {
          sharp: childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          publicURL
          id
        }
        title
      }
    }
  }
`
